import {HaNRoomListHorizontal, HaNWorkspacesListHorizontal} from '@organisms';
import {forwardRef} from 'react';
import {SwiperRefType} from '@molecules';
import {AreaWithSensorInfo, MeetingRoom} from '@lib/store';
import {useParams} from 'react-router-dom';

type HaNHorizontalListContentProps = {
  rooms: MeetingRoom[];
  areas: AreaWithSensorInfo[];
  onClick: (room: MeetingRoom | AreaWithSensorInfo) => void;
  startDateTime: string;
  showCalendarConsentMessageNoOccupancy: boolean;
  endDateTime: string;
  onSlideChange: (room: MeetingRoom | AreaWithSensorInfo) => void;
};

export const HaNHorizontalListContent = forwardRef<SwiperRefType, HaNHorizontalListContentProps>(
  (
    {
      endDateTime,
      onClick,
      rooms,
      onSlideChange,
      showCalendarConsentMessageNoOccupancy,
      startDateTime,
      areas,
    }: HaNHorizontalListContentProps,

    ref,
  ) => {
    const {tab} = useParams();
    switch (tab) {
      case 'rooms':
        return (
          <HaNRoomListHorizontal
            onClick={onClick}
            rooms={rooms}
            startDateTime={startDateTime}
            endDateTime={endDateTime}
            onSlideChange={onSlideChange}
            showCalendarConsentMessageNoOccupancy={showCalendarConsentMessageNoOccupancy}
            ref={ref}
          />
        );
      case 'workspaces':
        return (
          <HaNWorkspacesListHorizontal
            areas={areas}
            onClick={onClick}
            onSlideChange={onSlideChange}
            ref={ref}
          />
        );
      default:
        return (
          <HaNRoomListHorizontal
            onClick={onClick}
            rooms={rooms}
            startDateTime={startDateTime}
            showCalendarConsentMessageNoOccupancy={showCalendarConsentMessageNoOccupancy}
            endDateTime={endDateTime}
            onSlideChange={onSlideChange}
            ref={ref}
          />
        );
    }
  },
);
