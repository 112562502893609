import {useAppSelector, useBreakPoint, useFeatureToggle} from '@hooks';
import {
  getBuildingById,
  getFloorById,
  getFloorHasMap,
  getConnectedUsersByDate,
  getAreaById,
  getNodeInformationByNodeId,
  getIsOccupancyEnabled,
  getNodeInformationPerAreaByBuildingId,
  AreaWithSensorInfo,
} from '@lib/store';
import {useTranslation} from 'react-i18next';
import {
  InactiveStyledIcon,
  StyledButton,
  StyledParagraph,
  StyledSubtitle,
  StyledUserCell,
  WorkspaceInformationWrapper,
} from './styles';
import {HaNModalHeader, HaNWorkspaceModalPage} from '@organisms';
import {StyledBody} from '../HaNRoomInformation/styles';
import {CapacityIndicator, MapView} from '@molecules';
import {getDeskAmenityIcon, pxToRem} from '@utils';
import {HaNWorkspaceIcon} from '@organisms';
import {useMemo} from 'react';
import {FlexCol, FlexRow, H1, H2, H4, P, Subtitle} from '@quarks';
import {Icon} from '@atoms';
import {createState} from '@lib/utils';
import isToday from 'date-fns/isToday';
import startOfDay from 'date-fns/startOfDay';

type HaNWorkspaceInformationProps = {
  onClose: () => void;
  workspace: AreaWithSensorInfo;
  setPage: (pageName: HaNWorkspaceModalPage) => void;
};

export const HaNWorkspaceInformation = ({workspace, onClose, setPage}: HaNWorkspaceInformationProps) => {
  const {t} = useTranslation();
  const date = new Date();
  const floorHasMap = useAppSelector(getFloorHasMap(workspace.floorId));
  const {
    name,
    buildingId,
    floorId,
    activityTypes,
    deskAmenities,
    id: areaId,
    hasBookableDesks,
    sensorInformation,
    sensorUpdateTimeLabel,
    workspaceBookingPolicyType,
  } = workspace;
  const capacity = workspace.capacity || workspace.adHocCapacity;

  const selectedAreaState = useMemo(() => createState(workspace ? [workspace.id] : [], 'selected'), [workspace]);
  const area = useAppSelector((state) => getAreaById(state, areaId))!;
  const areaBookingStates = useAppSelector((state) =>
    getNodeInformationPerAreaByBuildingId(state, startOfDay(new Date()).toISOString(), buildingId),
  );
  const building = useAppSelector((state) => getBuildingById(state, buildingId));
  const isOccupancyEnabled = useAppSelector(getIsOccupancyEnabled);
  const floor = useAppSelector((state) => getFloorById(state, floorId));
  const connections = useAppSelector((state) =>
    getConnectedUsersByDate(state, {date: date.toISOString(), nodeId: areaId}),
  );
  const selectorProps = useMemo(() => ({nodeId: areaId, date: new Date()}), [areaId]);
  const areaInformation = useAppSelector((state) => getNodeInformationByNodeId(state, selectorProps));

  const deskFullyBooked = areaInformation.capacity - areaInformation.occupation <= 0;

  const breakPoint = useBreakPoint();
  const showMapThumbnail = floorHasMap && breakPoint === 'small';
  const areaInfo = (areaBookingStates ?? {})[area.id];
  const {AdHocWorkspaces} = useFeatureToggle();

  return (
    <WorkspaceInformationWrapper data-testid="HaNWorkspaceInformation-MainWrapper">
      <HaNModalHeader
        onClose={onClose}
        title={t('workplace:Area')}
      />
      <StyledBody as="section">
        {building?.name || floor?.name ? (
          <FlexCol
            gap={16}
            data-testid="HaNWorkspaceInfromation-BuildingAndFloorNameWrapper">
            <H1 as="h2">{name}</H1>
            <P>{[t('meeting:MeetingRoomSeat', {count: capacity}), building?.name, floor?.name].join(', ')}</P>
          </FlexCol>
        ) : null}
        <FlexCol gap={16}>
          <H2 as="h3">{t('screen:InviteesAvailability')}</H2>

          {sensorInformation && (
            <FlexRow
              gap={16}
              alignItems="center"
              justifyContent="flex-start">
              <HaNWorkspaceIcon iconType={sensorInformation.status} />
              <FlexCol>
                <H4>{sensorInformation?.title}</H4>
                <Subtitle>{sensorUpdateTimeLabel}</Subtitle>
              </FlexCol>
            </FlexRow>
          )}

          {!sensorInformation && isOccupancyEnabled ? (
            <FlexRow
              gap={16}
              alignItems="center"
              justifyContent="flex-start">
              <InactiveStyledIcon
                icon="noBroadcast"
                size="24px"
              />
              <FlexCol>
                <H4>{t('NoSensorInstalled')}</H4>
              </FlexCol>
            </FlexRow>
          ) : null}

          {areaInformation && (
            <FlexRow
              gap={16}
              alignItems="center"
              justifyContent="flex-start">
              {AdHocWorkspaces && workspaceBookingPolicyType === 'NonBookable' ? (
                <FlexRow
                  gap={16}
                  alignItems="center">
                  <InactiveStyledIcon
                    icon="noCalendar"
                    size="24px"
                  />
                  <H4>{t('hereAndNow:AdHocUse')}</H4>
                </FlexRow>
              ) : (
                <>
                  <InactiveStyledIcon
                    icon="calendar"
                    size="24px"
                  />
                  <FlexCol>
                    <H4>{t(hasBookableDesks ? 'screen:DeskDetail' : 'hereAndNow:AreaBooking')}</H4>
                    <FlexRow gap={8}>
                      <CapacityIndicator
                        capacity={areaInformation.capacity}
                        occupation={areaInformation.occupation}
                        displayDescription={false}
                      />
                      <StyledSubtitle $alertState={deskFullyBooked}>
                        {!deskFullyBooked
                          ? `${areaInformation.capacity - areaInformation.occupation}/${areaInformation.capacity} ${t(
                              'workplace:WorkspaceGroupAvailable',
                            )}`
                          : `${t('translation:FullyBooked')}`}
                      </StyledSubtitle>
                    </FlexRow>
                  </FlexCol>
                </>
              )}
            </FlexRow>
          )}

          {sensorInformation && (
            <StyledButton
              button="text"
              onClick={() => setPage('availabilityInformation')}>
              {t('hereAndNow:LearnMore')}
            </StyledButton>
          )}
        </FlexCol>
        {activityTypes.length ? (
          <FlexCol
            gap={16}
            data-testid="HaNWorkspaceInformation-ActivityTypesWrapper">
            <H2 as="h3">{t('workplace:ActivityTypesFilterSectionTitle')}</H2>
            <StyledParagraph>
              {activityTypes.map((activity) => t(`activityTypes:activityType`, {context: activity})).join(', ')}
            </StyledParagraph>
          </FlexCol>
        ) : null}
        {deskAmenities.length ? (
          <FlexCol
            gap={16}
            data-testid="HaNWorkspaceInformation-DeskAmenitiesWrapper">
            <H2 as="h3">{t('deskAmenities:deskAmenity_plural')}</H2>
            <P>{t('workplace:DeskAmenities')}</P>
            {deskAmenities.map((amenity) => {
              return (
                <FlexRow
                  gap={8}
                  key={amenity}
                  alignItems="center"
                  justifyContent="flex-start">
                  <Icon
                    weight="thin"
                    size={pxToRem(24)}
                    icon={getDeskAmenityIcon(amenity)}></Icon>
                  <P>{t(`deskAmenities:deskAmenity`, {context: amenity})}</P>
                </FlexRow>
              );
            })}
          </FlexCol>
        ) : null}
        {/* TODO: PUT BACK MAP */}
        {showMapThumbnail && (
          <FlexCol gap={16}>
            <H2 as="h3">{t('translation:LocationTitle')}</H2>
            <MapView
              buildingId={buildingId}
              floorId={floorId}
              buildingNodeStates={selectedAreaState}
              borderRadius={8}
              fullView={false}
              highlights={[
                {
                  type: 'area',
                  nodeId: workspace.id,
                  available: areaInfo ? areaInfo.capacity - areaInfo.occupation : area.capacity || area.adHocCapacity,
                  connections: [],
                },
              ]}
              disablePointerEvents={true}
              data-testid="HaNWorkspaceInformation-Map"
            />
          </FlexCol>
        )}
        {connections.length ? (
          <FlexCol gap={16}>
            <H2 as="h3">{t('connection:ConnectionsThatBookedHere')}</H2>
            {connections.map((connection) => (
              <StyledUserCell
                shouldShowCheckIn={isToday(date)}
                user={connection}
                key={JSON.stringify(connection)}
              />
            ))}
          </FlexCol>
        ) : null}
      </StyledBody>
    </WorkspaceInformationWrapper>
  );
};
