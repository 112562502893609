import {useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ParkingSelectionCardProps} from './types';
import {
  ParkingNode,
  getFilteredAndSortedParkingLots,
  getParkingNodesWithAvailabilityGroup,
  getParkingReservationByDate,
  groupParkingNodesByAvailability,
  removeParkingSpot,
  withAsyncThunkErrorHandling,
} from '@lib/store';
import {useAppDispatch, useAppSelector, useDialog, useModal} from '@hooks';
import {FlexCol, FlexRow, Span, Subtitle} from '@quarks';
import {ChipFilterNew} from '@molecules';
import {ModalPage, ModalPageBottom, ParkingSelectionCell} from '@organisms';
import {constructCalendarOverviewParkingFilter, useFilterData} from '@lib/utils';

export const ParkingSelectionCard = ({date}: ParkingSelectionCardProps) => {
  const {t} = useTranslation();
  const {closeModal, removePages} = useModal();
  const {destructiveDialog} = useDialog();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  const parkingReservation = useAppSelector((state) => getParkingReservationByDate(state, date));

  const parkingNodes = useAppSelector((state) => getParkingNodesWithAvailabilityGroup(state, date));
  const allParkingLots = useAppSelector(getFilteredAndSortedParkingLots);

  const filterConfig = useMemo(() => constructCalendarOverviewParkingFilter(allParkingLots, t), [allParkingLots, t]);
  const {filteredData} = useFilterData('CalendarOverview_Parking', 'cacheKey', parkingNodes, filterConfig);
  const {Available, Unavailable} = groupParkingNodesByAvailability(filteredData as ParkingNode[]);

  const handleShowDeleteParkingDialog = async () => {
    if (!parkingReservation) return;

    const confirmation = await destructiveDialog(
      t('parking:DeleteParkingAlertTitle'),
      t('parking:DeleteParkingAlertMessage'),
      'car',
    );

    if (confirmation) {
      dispatch(withAsyncThunkErrorHandling(() => removeParkingSpot(parkingReservation.id)));
      closeModal();
    }
  };

  return (
    <ModalPage
      title={t('parking:BookParkingSpot')}
      onClose={closeModal}
      bottom={
        <ModalPageBottom
          destructive={true}
          buttonLabel={t('workplace:DeleteBooking')}
          buttonDisabled={!parkingReservation}
          onClick={handleShowDeleteParkingDialog}
          buttonLoading={loading}
        />
      }>
      <FlexCol gap={16}>
        <FlexRow
          justifyContent="flex-start"
          gap={8}>
          <ChipFilterNew
            filterSlice="CalendarOverview_Parking"
            areFilterTransparent
            goBackFromFilterModal={() => removePages(1)}
          />
        </FlexRow>

        <FlexCol gap={8}>
          {Available.map((parkingNode, index) => (
            <ParkingSelectionCell
              key={`parkingNode-Available-${index}`}
              parkingNode={parkingNode}
              date={date}
              loading={loading}
              setLoading={(value) => setLoading(value)}
            />
          ))}
        </FlexCol>

        {Unavailable.length > 0 && (
          <FlexCol gap={8}>
            <Span fontWeight="bold">{t('workplace:WorkspaceGroupUnavailable')}</Span>
            {Unavailable.map((parkingNode, index) => (
              <ParkingSelectionCell
                key={`parkingNode-Unavailable-${index}`}
                parkingNode={parkingNode}
                date={date}
                loading={loading}
                setLoading={(value) => setLoading(value)}
              />
            ))}
          </FlexCol>
        )}

        <Subtitle fontSize="sm">{t('parking:NoParkingInTheList')}</Subtitle>
      </FlexCol>
    </ModalPage>
  );
};
