import {useParams} from 'react-router-dom';
import {useAppSelector} from '@hooks';
import {getIsWorkspacesOnHereAndNowEnabled} from '@lib/store';
import {ChipFilterNew} from '@molecules';

type Props = {
  swiperFilterReset: () => void;
  areFilterTransparent?: boolean;
};

export const HaNFilters = ({swiperFilterReset, areFilterTransparent}: Props) => {
  const {tab} = useParams();
  const isWorkspacesOnHereAndNowEnabled = useAppSelector(getIsWorkspacesOnHereAndNowEnabled);

  return tab === 'rooms' || (!isWorkspacesOnHereAndNowEnabled && !tab) ? (
    <ChipFilterNew
      filterSlice="HereAndNow_Rooms"
      testIdPrefix="HaNRoomFilters"
      swiperFilterReset={swiperFilterReset}
      areFilterTransparent={areFilterTransparent}
    />
  ) : (
    <ChipFilterNew
      filterSlice="HereAndNow_Workspaces"
      testIdPrefix="HaNWorkSpaceFilters"
      swiperFilterReset={swiperFilterReset}
      areFilterTransparent={areFilterTransparent}
    />
  );
};
