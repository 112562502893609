import {useAppDispatch, useAppSelector} from '@hooks';
import {Facility, getFacilitiesByFloorId, loadFacilities, withAsyncThunkErrorHandling} from '@lib/store';
import {useEffect, useMemo} from 'react';
import {FacilityMarkerData} from '../../../../../../submodules/map/mapiq-map/markers/NodeMarkerData';

const NO_FACILITIES: Facility[] = [];

// Note: we have many nullable floor id strings in our components, so even though not ideal it's probably
// best to also handle those here

export const useFacilityMarkers = (buildingId: string, floorId: string | null) => {
  const dispatch = useAppDispatch();
  const facilitiesData = useAppSelector(getFacilitiesByFloorId(floorId ?? ''));
  const facilities = facilitiesData.status === 'Loaded' ? facilitiesData.facilities : NO_FACILITIES;

  useEffect(
    function loadFacilitiesForFloor() {
      if (!!floorId && facilitiesData.status === 'NotLoaded') {
        dispatch(withAsyncThunkErrorHandling(() => loadFacilities({buildingId, floorId})));
      }
    },
    [buildingId, dispatch, facilitiesData.status, floorId],
  );

  const markers = useMemo((): FacilityMarkerData[] => {
    return facilities.map((f) => ({
      nodeId: f.id,
      type: 'facility',
      subType: f.facilityType.id,
    }));
  }, [facilities]);

  return markers;
};
