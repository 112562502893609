import {Icon} from '@atoms';
import {FlexCol, P, Span} from '@quarks';
import {truncateString} from '@utils';
import styled from 'styled-components';

export const StyledText = styled(Span)`
  ${truncateString}
  flex-grow: 1;
`;

export const StyledLabel = styled.label`
  cursor: pointer;
  display: flex;
  gap: 16px;
  padding: 16px 0;
  width: 100%;
  pointer-events: none;
`;

export const StyledContainer = styled.div`
  width: 100%;

  &:focus-visible {
    outline: 2px solid ${({theme}) => theme.brand};
    outline-offset: 4px;
  }
`;

export const StyledIcon = styled(Icon).attrs((attrs) => ({
  ...attrs,
  color: attrs.theme.icon.inactive,
}))``;

export const StyledFlexCol = styled(FlexCol)`
  width: 100%;
`;

export const StyledInputLabel = styled.label`
  justify-content: space-between;
  align-items: stretch;
  display: flex;
  padding: 16px 8px;
  width: 100%;
  border-radius: 4px;

  transition: background var(--speed-fast), border var(--speed-fast);

  @media (hover) {
    &:hover {
      background: ${({theme}) => theme.card.opaque.hover.background};
      color: inherit !important;
      text-decoration: none !important;
      cursor: pointer;
    }
  }

  &:focus-visible {
    border: 2px solid ${({theme}) => theme.brand};
    color: inherit !important;
    text-decoration: none !important;
    outline: none;

    & > div > div:nth-child(3) > svg {
      transform: translate3d(4px, 0, 0);
    }
  }
`;

export const LearnMore = styled(P)`
  font-weight: 600;
  padding: 16px 8px;
  font-size: 15px;
  color: ${({theme}) => theme.brand};
`;
