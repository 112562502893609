import {FlexCol, H2} from '@quarks';
import {MeetingRoomDataProps} from './types';
import {useTranslation} from 'react-i18next';
import {MeetingRoomScheduleData} from '@organisms';
import {Button} from '@molecules';
import {useAppSelector, useFeatureToggle} from '@hooks';
import {
  getIsHybridMeetingsFeatureEnabled,
  getIsMeetingsCalendarEnabled,
  getIsOccupancyEnabled,
  getMeetingCreationStatus,
} from '@lib/store';

export const MeetingRoomData = ({sensorStatus, scheduleStatus, onLearnMorePress}: MeetingRoomDataProps) => {
  const {t} = useTranslation();
  const isCreatingmeeting = useAppSelector(getMeetingCreationStatus) === 'Loading';
  const isMeetingsCalendarAdminEnabled = useAppSelector(getIsMeetingsCalendarEnabled);
  const isHybridMeetingsFeatureEnabled = useAppSelector(getIsHybridMeetingsFeatureEnabled);
  const showSensorData = useAppSelector(getIsOccupancyEnabled);
  const showScheduleData = isMeetingsCalendarAdminEnabled && isHybridMeetingsFeatureEnabled;
  const {AdHocWorkspaces} = useFeatureToggle();

  return showSensorData || showScheduleData ? (
    <FlexCol
      gap={16}
      alignItems="flex-start">
      <H2 as="h3">{t('screen:InviteesAvailability')}</H2>
      {showSensorData && (
        <MeetingRoomScheduleData
          data-testid="MeetingRoomDetailsSensorDataIcon"
          status={sensorStatus?.sensorStatus}
          title={sensorStatus?.sensorTitle ? sensorStatus.sensorTitle : t('NoSensorInstalled')}
          subtitle={sensorStatus?.sensorSubtitle}
          icon={sensorStatus ? 'broadcast' : 'noBroadcast'}
        />
      )}
      {showScheduleData && (
        <MeetingRoomScheduleData
          data-testid="MeetingRoomDetailsScheduleDataIcon"
          status={scheduleStatus?.scheduleStatus}
          title={
            scheduleStatus?.scheduleTitle
              ? scheduleStatus.scheduleTitle
              : AdHocWorkspaces && !scheduleStatus?.scheduleTitle
              ? t('hereAndNow:AdHocUse')
              : t('hereAndNow:NotBookable')
          }
          subtitle={scheduleStatus?.scheduleSubtitle}
          icon={scheduleStatus ? 'calendar' : 'noCalendar'}
        />
      )}
      <Button
        button="text"
        disabled={isCreatingmeeting}
        onClick={onLearnMorePress}>
        {t('hereAndNow:LearnMore')}
      </Button>
    </FlexCol>
  ) : null;
};
